@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "Founders Grotesk Fallback";
    size-adjust: 87.9%;
    ascent-override: 90%;
    src: local("Helvetica");
}

@font-face {
    font-family: "Founders Grotesk";
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: url(./fonts/founders-grotesk-regular.woff2) format("woff2");
}

@font-face {
    font-family: "Founders Grotesk";
    font-style: normal;
    font-weight: 500;
    font-display: fallback;
    src: url(./fonts/founders-grotesk-medium.woff2) format("woff2");
}

@font-face {
    font-family: "Founders Grotesk";
    font-style: normal;
    font-weight: 600;
    font-display: fallback;
    src: url(./fonts/founders-grotesk-semibold.woff2) format("woff2");
}

@font-face {
    font-family: "Founders Grotesk";
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: url(./fonts/founders-grotesk-bold.woff2) format("woff2");
}

@font-face {
    font-family: "Founders Grotesk";
    font-style: normal;
    font-weight: 300;
    font-display: fallback;
    src: url(./fonts/founders-grotesk-light.woff2) format("woff2");
}

.page-container {
    @apply w-1/2;
}


.page {
    @apply h-max;
    position: relative;
}

.pageBreak {
    page-break-after: always;
}

@page {
    size: A4;
    margin: 0;
}

@media print {
    .page {
        width: 21cm;
        height: 29.7cm;
        border-right: none;
        box-shadow: none;
        padding:0;
        margin: 0;
    }

    .footer {
        position: fixed;
        bottom: 0;
        width: 21cm;
        height: 3cm;
    }
    
    .page-container {
        @apply m-0 w-auto;
    }

    .page, div {
        break-inside: avoid;
        page-break-inside: avoid;
    }

    .no-print, .no-print * {
        display: none !important;
    }

    .no-print-invisible, .no-print-invisible * {
        @apply invisible;
    }

    .Toastify {
        display: none !important;
        @apply invisible;
    }
}
